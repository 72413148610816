<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input class="filter-item" v-model="listQuery.order_no" placeholder="订单号" style="width: 220px;" clearable/>
      <el-input class="filter-item" v-model="listQuery.phone" placeholder="手机号" style="width: 160px;" clearable/>
      <el-input class="filter-item" v-model="listQuery.name" placeholder="姓名" style="width: 120px;" clearable/>
      <el-input class="filter-item" v-model="listQuery.goods_name" placeholder="请输入商品名称" style="width: 180px;" clearable/>
      <el-select class="filter-item" v-model="listQuery.order_type" placeholder="订单类型" style="width: 140px" clearable>
        <el-option label="外卖" :value="1"></el-option>
        <el-option label="商城" :value="2"></el-option>
<!--        <el-option label="夜猫店" :value="3"></el-option>-->
<!--        <el-option label="零食盒子" :value="4"></el-option>-->
      </el-select>

      <!-- 店铺管理员隐藏该选型 -->
      <template v-if="user.user.type !== 'shop'">
        <el-select class="filter-item" filterable v-model="listQuery.canteen_id" placeholder="所属食堂" style="width: 140px" clearable>
          <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
          </el-option>
        </el-select>
        <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 140px" clearable>
          <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
          </el-option>
        </el-select>
      </template>

      <el-select class="filter-item" v-model="listQuery.order_status" placeholder="订单状态" style="width: 140px" clearable>
        <el-option label="待接单" :value="1"></el-option>
        <el-option label="待配送" :value="2"></el-option>
        <el-option label="已退款" :value="3"></el-option>
        <el-option label="已完成" :value="4"></el-option>
        <el-option label="无分拣" :value="5"></el-option>
        <el-option label="无骑手" :value="6"></el-option>
        <el-option label="配餐超时" :value="7"></el-option>
        <el-option label="配送超时" :value="8"></el-option>
        <el-option label="待发货" :value="9"></el-option>
        <el-option label="待收货" :value="10"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.pay_type" placeholder="支付方式" style="width: 140px" clearable>
        <el-option label="微信" :value="1"></el-option>
        <el-option label="余额" :value="2"></el-option>
        <el-option label="积分" :value="3"></el-option>
        <el-option label="数字人民币" :value="4"></el-option>
        <el-option label="易票联微信" :value="5"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.send_type" placeholder="配送方式" style="width: 140px" clearable>
        <el-option label="配送服务" :value="1"></el-option>
        <el-option label="智慧餐柜" :value="2"></el-option>
        <el-option label="自取服务" :value="3"></el-option>
      </el-select>
      <el-select class="filter-item" filterable v-model="listQuery.building_id" placeholder="楼栋" style="width: 140px" clearable>
        <el-option v-for="item in buildings" :key="item.id" :label="item.building_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" filterable v-model="listQuery.sendman_id" placeholder="配送员" style="width: 140px" clearable>
        <el-option v-for="item in sendmans" :key="item.id" :label="item.name" :value="item.id">
          <div style="display: flex;align-items:center;text-align:center;">
            <img :src="item.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
            <span>{{ item.name }} / {{item.phone}}</span>
          </div>
        </el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.send_mode" placeholder="送餐方式" style="width: 140px" clearable>
        <el-option label="上楼配送" :value="0"></el-option>
        <el-option label="投入餐柜" :value="1"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.activities_type" placeholder="店铺活动类型" style="width: 140px" clearable>
        <el-option label="满减活动" :value="1"></el-option>
        <el-option label="折扣商品" :value="2"></el-option>
        <el-option label="优惠券" :value="3"></el-option>
        <el-option label="超值换购" :value="4"></el-option>
        <el-option label="第二份半价" :value="5"></el-option>
        <el-option label="买赠活动" :value="6"></el-option>
        <el-option label="满赠活动" :value="7"></el-option>
        <el-option label="门店新客立减" :value="8"></el-option>
        <el-option label="收藏有礼" :value="9"></el-option>
        <el-option label="下单返券" :value="10"></el-option>
        <el-option label="集单返券" :value="11"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.activity_type" placeholder="订单活动类型" style="width: 140px" clearable>
        <el-option label="商家自营" :value="0"></el-option>
        <el-option label="限时抢购" :value="1"></el-option>
        <el-option label="天天特价" :value="2"></el-option>
      </el-select>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleExportExcel">导出</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="expand" min-width="40">
        <template slot-scope="props">
          <div style="margin-left: 10%">
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>用户信息</el-row>
              <el-form-item label="昵称:">
                <span>{{ props.row.user.nickname }}</span>
              </el-form-item>
              <el-form-item label="姓名:">
                <span>{{ props.row.user.name }}</span>
              </el-form-item>
              <el-form-item label="手机号码:">
                <span>{{ props.row.user.phone }}</span>
              </el-form-item>
              <el-form-item label="openid:">
                <span>{{ props.row.user.openid }}</span>
              </el-form-item>
            </el-form>
            <el-form v-if="props.row.send_type == 1" label-position="left" inline class="custom-inline-form">
              <el-row>收货人信息</el-row>
              <el-form-item label="姓名:">
                <span>{{ props.row.receiver.name }}</span>
              </el-form-item>
              <el-form-item label="手机号码:">
                <span>{{ props.row.receiver.phone }}</span>
              </el-form-item>
              <el-form-item label="地址:">
                <span>{{ props.row.receiver.address }}</span>
              </el-form-item>
            </el-form>
            <el-form v-else-if="props.row.send_type == 2" label-position="left" inline class="custom-inline-form">
              <el-row>收货人信息</el-row>
              <el-form-item label="姓名:">
                <span>{{ props.row.user.name }}</span>
              </el-form-item>
              <el-form-item label="手机号码:">
                <span>{{ props.row.user.phone }}</span>
              </el-form-item>
              <el-form-item label="地址:">
                <span>{{ props.row.device_order.device_name }}-{{ props.row.device_order.box_name }}</span>
              </el-form-item>
            </el-form>
            <el-form v-else-if="props.row.send_type == 3" label-position="left" inline class="custom-inline-form">
              <el-row>收货人信息</el-row>
              <el-form-item label="姓名:">
                <span>{{ props.row.user.name }}</span>
              </el-form-item>
              <el-form-item label="手机号码:">
                <span>{{ props.row.self_take.take_phone }}</span>
              </el-form-item>
              <el-form-item label="地址:">
                <span>到店自取</span>
              </el-form-item>
            </el-form>
            <el-form v-if="props.row.order_type == 2 && props.row.shipment_status > 0" label-position="left" inline class="custom-inline-form">
              <el-row>发货信息</el-row>
              <el-form-item label="物流单号:">
                <span>{{ props.row.courier_no }}</span>
              </el-form-item>
              <el-form-item label="发货时间:">
                <span>{{ props.row.shipment_time }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>配送信息</el-row>
              <el-form-item label="配送方式:">
                <span v-if="props.row.send_type == 1">配送服务</span>
                <span v-else-if="props.row.send_type == 2">智慧餐柜</span>
                <span v-else-if="props.row.send_type == 3">自取服务</span>
              </el-form-item>
              <el-form-item :label="props.row.send_type == 3 ? '预计自取时间:' : '预计送达时间:'">
                <span>{{ props.row.send_time ? props.row.send_time : '尽快送出' }}</span>
              </el-form-item>
              <el-form-item label="送餐方式:" v-if="props.row.send_type == 1">
                <span v-if="props.row.is_sideboard == 1">投入餐柜</span>
                <span v-else>上楼配送</span>
              </el-form-item>
              <br/>
              <el-form-item label="骑手:">
                <span>{{ props.row.sendman ? `${props.row.sendman.name}-${props.row.sendman.phone}` : '无' }}</span>
              </el-form-item>
              <el-form-item label="骑手取餐时间:">
                <span>{{ props.row.sendman_take_time ? `${props.row.sendman_take_time}` : '无' }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="分拣员:">
                <span>{{ props.row.distributeman_record ? `${props.row.distributeman_record.distributeman.user.name}-${props.row.distributeman_record.distributeman.user.phone}` : '无' }}</span>
              </el-form-item>
              <el-form-item label="分拣时间:">
                <span>{{ props.row.distributeman_record ? `${props.row.distributeman_record.created_at}` : '无' }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="配餐状态:" v-if="props.row.send_type == 1">
                <span v-if="props.row.is_meal_timeout == 1">已超时</span>
                <span v-else>未超时</span>
              </el-form-item>
              <el-form-item label="配送状态:" v-if="props.row.send_type == 1">
                <span v-if="props.row.is_shipping_timeout == 1">已超时</span>
                <span v-else>未超时</span>
              </el-form-item>
              <el-form-item :label="props.row.send_type == 3 ? '外卖自取时间:' : '外卖送达时间:'">
                <span>{{ props.row.shipping_time ? props.row.shipping_time : '无' }}</span>
              </el-form-item>
            </el-form>
            <el-form v-if="props.row.sideboard_order" label-position="left" inline class="custom-inline-form">
              <el-row>投餐信息</el-row>
              <el-form-item label="投餐类型:">
                <span v-if="props.row.sideboard_order.put_meal_type == 1">订单号投餐</span>
                <span v-else-if="props.row.sideboard_order.put_meal_type == 2">专属号投餐</span>
                <span v-else-if="props.row.sideboard_order.put_meal_type == 3">手机号投餐</span>
              </el-form-item>
              <el-form-item label="投餐状态:">
                <span v-if="props.row.sideboard_order.state == 1">已投餐</span>
                <span v-else-if="props.row.sideboard_order.state == 2">已确认投餐</span>
                <span v-else-if="props.row.sideboard_order.state == 3">已取餐</span>
                <span v-else-if="props.row.sideboard_order.state == 4">取消投餐</span>
              </el-form-item>
              <el-form-item label="投餐员:">
                <span>{{ props.row.sideboard_order.sendman ? `${props.row.sideboard_order.sendman.name}-${props.row.sideboard_order.sendman.phone}` : '无' }}</span>
              </el-form-item>
              <el-form-item label="投餐时间:">
                <span>{{ props.row.sideboard_order.put_meal_time ? `${props.row.sideboard_order.put_meal_time}` : '' }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="设备id:">
                <span>{{ props.row.sideboard_order.device_id  }}</span>
              </el-form-item>
              <el-form-item label="设备名称:">
                <span>{{ props.row.sideboard_order.device_name  }}</span>
              </el-form-item>
              <el-form-item label="占用的格子号:">
                <span>{{ props.row.sideboard_order.cell_id  }}</span>
              </el-form-item>
              <el-form-item label="占用的格子号别名:">
                <span>{{ props.row.sideboard_order.cell_alias  }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="取餐时间:">
                <span>{{ props.row.sideboard_order.take_meal_time }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>订单信息</el-row>
              <el-form-item label="学校:">
                <span>{{ props.row.school.school_name }}</span>
              </el-form-item>
              <el-form-item label="店铺:">
                <span>{{ props.row.shop.shop_name }}</span>
              </el-form-item>
              <el-form-item label="订单编号:">
                <span>{{ props.row.order_no }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="商品总价:">
                <span>{{ props.row.original_price }}元</span>
              </el-form-item>
              <el-form-item label="包装费:">
                <span>{{ props.row.pack_price }}元</span>
              </el-form-item>
              <el-form-item label="配送费:">
                <span>{{ props.row.send_price }}元</span>
              </el-form-item>
              <el-form-item label="商家承担配送费:">
                <span>{{ props.row.shop_bear_send_fee }}元</span>
              </el-form-item>
              <el-form-item label="用户承担配送费:">
                <span>{{ props.row.user_bear_send_fee }}元</span>
              </el-form-item>
              <el-form-item label="订单总价:">
                <span>{{ props.row.total_price }}元</span>
              </el-form-item>
              <el-form-item label="实付金额:">
                <span>{{ props.row.pay_money }}元</span>
              </el-form-item>
              <br/>
              <el-form-item label="总优惠:">
                <span>{{ props.row.discount_money }}元</span>
              </el-form-item>
              <el-form-item label="系统优惠:">
                <span>{{ props.row.discount_money_system }}元</span>
              </el-form-item>
              <el-form-item label="平台优惠:">
                <span>{{ props.row.discount_money_platform }}元</span>
              </el-form-item>
              <el-form-item label="商家优惠:">
                <span>{{ props.row.discount_money_shop }}元</span>
              </el-form-item>
              <br/>
              <el-form-item label="下单时间:">
                <span>{{ props.row.created_at }}</span>
              </el-form-item>
              <el-form-item label="支付时间:">
                <span>{{ props.row.pay_time }}</span>
              </el-form-item>
              <el-form-item label="退款时间:">
                <span>{{ props.row.refund_time }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="备注:">
                <span>{{ props.row.remark }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>商品信息</el-row>
              <div v-for="(item, index) in props.row.details" :key="index">
                <el-form-item label="ID:">
                  <span>{{ item.id }}</span>
                </el-form-item>
                <el-form-item label="名称:">
                  <span>{{ item.goods_name }}</span>
                </el-form-item>
                <el-form-item label="规格属性:">
                  <span>{{ item.goods_spec_attr_info }}</span>
                </el-form-item>
                <el-form-item label="价格:">
                  <span>{{ item.total_price }}元</span>
                </el-form-item>
                <el-form-item label="数量:">
                  <span>{{ item.goods_num }}</span>
                </el-form-item>
                <el-form-item label="合计:">
                  <span>{{ item.total_price }}元</span>
                </el-form-item>
                <br/>
              </div>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="ID" width="80">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop.shop_name }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="用户" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.receiver.name }}</div>
          <div>{{ scope.row.receiver.phone }}</div>
        </template>
      </el-table-column> -->
      <el-table-column label="打印号" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop_count }}
        </template>
      </el-table-column>
      <el-table-column label="实付金额" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_money }}
        </template>
      </el-table-column>
      <el-table-column label="优惠金额" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.discount_money }}
        </template>
      </el-table-column>
      <el-table-column label="订单类型" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.order_type === 1">外卖</span>
          <span v-else-if="scope.row.order_type === 2">商城</span>
<!--          <span v-else-if="scope.row.order_type == 3">夜猫店</span>-->
<!--          <span v-else-if="scope.row.order_type == 4">零食盒子</span>-->
        </template>
      </el-table-column>
      <el-table-column label="支付方式" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.pay_type === 1">微信</el-tag>
          <el-tag type="primary" v-else-if="scope.row.pay_type === 2">余额</el-tag>
          <el-tag type="warning" v-else-if="scope.row.pay_type === 3">积分</el-tag>
          <el-tag type="danger" v-else-if="scope.row.pay_type === 4">数字人民币</el-tag>
          <el-tag type="success" v-else-if="scope.row.pay_type === 5">易票联微信</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.order_status == 0">未接单</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_status == 1">已接单</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_status == 2">待评价</el-tag>
          <el-tag type="success" v-else-if="scope.row.order_status == 3">已完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.order_status == 4">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="发货状态" min-width="80" align="center">
        <template slot-scope="scope">
          <!-- 兼容商城发货 -->
          <el-tag type="warning" v-if="scope.row.order_type == 2 && scope.row.shipping_status == 1">配送中</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_type == 2 && scope.row.shipment_status == 0">待发货</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_type == 2 && scope.row.shipment_status == 1">待收货</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_type == 2 && scope.row.shipment_status == 2">已收货</el-tag>

          <el-tag type="info" v-else-if="scope.row.shipping_status == 0 && scope.row.send_type == 3">待自提</el-tag>
          <el-tag type="info" v-else-if="scope.row.shipping_status == 0">未配送</el-tag>
          <el-tag type="warning" v-else-if="scope.row.shipping_status == 1">配送中</el-tag>
          <el-tag type="success" v-else-if="scope.row.shipping_status == 2 && scope.row.send_type == 3">已自提</el-tag>
          <el-tag type="success" v-else-if="scope.row.shipping_status == 2">已送达</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="支付状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.pay_status == 0">未支付</el-tag>
          <el-tag type="success" v-else-if="scope.row.pay_status == 1">已支付</el-tag>
          <el-tag type="danger" v-else-if="scope.row.pay_status == 2">已退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="下单时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配餐状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_meal_timeout === 1">已超时</el-tag>
          <el-tag type="success" v-else>未超时</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="配送状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_shipping_timeout === 1">已超时</el-tag>
          <el-tag type="success" v-else>未超时</el-tag>
        </template>
      </el-table-column>
      <!-- 学校监管和食堂监管只能查看订单不能操作 -->
      <el-table-column v-if="user.user.type !== 'school_supervisor' && user.user.type !== 'canteen_supervisor'" label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handlePrint(scope.row)">打印</el-button>
          <el-button v-if="scope.row.order_status !== 3 && scope.row.order_status !== 4 && scope.row.pay_status !== 2" type="danger" size="mini" @click="handleCancel(scope.row)">取消订单</el-button>
          <el-button v-if="scope.row.pay_status === 1 && scope.row.order_status === 0" type="success" size="mini" @click="handleConfirm(scope.row)">接单</el-button>
          <!-- <el-button v-if="scope.row.pay_status === 1 && scope.row.order_status === 1 && scope.row.shipping_status === 0" type="success" size="mini" @click="handleSend(scope.row)">配送</el-button> -->
          <el-button v-if="scope.row.pay_status === 1 && scope.row.order_type === 2 && scope.row.order_status === 1 && scope.row.shipment_status === 0" type="warning" size="mini" @click="handleOrderShipment(scope.row)">发货</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="display: none" id="exportTable" v-loading="listLoading" :data="exportExcels" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="订单号" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.order_no }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺名" min-width="110" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shop.shop_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="打印号" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shop_count }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.order_status == 0">未接单</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_status == 1">已接单</el-tag>
          <el-tag type="info" v-else-if="scope.row.order_status == 2">待评价</el-tag>
          <el-tag type="success" v-else-if="scope.row.order_status == 3">已完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.order_status == 4">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="顾客实付" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pay_money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平台补贴" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.discount_money_platform }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商家应收" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pay_money - scope.row.finance_ledger_money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货人" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.send_type == 1">{{ scope.row.receiver.name }}</span>
          <span v-else>{{ scope.row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货人电话" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.send_type == 1">{{ scope.row.receiver.phone }}</span>
          <span v-else-if="scope.row.send_type == 2">{{ scope.row.user.phone }}</span>
          <span v-else-if="scope.row.send_type == 3">{{ scope.row.self_take.take_phone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货地址" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.send_type == 1">{{ scope.row.receiver.address }}</span>
          <span v-else-if="scope.row.send_type == 2">{{ scope.row.device_order.device_name }}</span>
          <span v-else-if="scope.row.send_type == 3">到店自取</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="下单时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商品信息" min-width="120">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.details" :key="index">{{item.goods_name}}x{{item.goods_num}}份,</span>
        </template>
      </el-table-column>
      <el-table-column label="满减活动" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.discount_money_shop>0 ? -scope.row.discount_money_shop : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="堂食满减" min-width="80" align="center">
        <template slot-scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="配送费" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.send_price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商家承担配送费" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shop_bear_send_fee }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户承担配送费" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.user_bear_send_fee }}</span>
        </template>
      </el-table-column>
      <el-table-column label="堂食订单" min-width="80" align="center">
        <template slot-scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="其他金额" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.finance_ledger_money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算金额" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pay_money - scope.row.finance_ledger_money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分拣时间" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.distributeman_record ? `${scope.row.distributeman_record.created_at}` : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="骑手取餐时间" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sendman_take_time ? `${scope.row.sendman_take_time}` : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="投餐时间" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.sideboard_order ? `${scope.row.sideboard_order.put_meal_time}` : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="退款时间" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.refund_time ? `${scope.row.refund_time}` : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="物流单号" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.courier_no ? `${scope.row.courier_no}` : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发货时间" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shipment_time ? `${scope.row.shipment_time}` : '' }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 订单发货 -->
    <el-dialog title="订单发货" :visible.sync="dialogOrderShipmentVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="formOrderShipment" :rules="rulesOrderShipment" :model="formOrderShipment" label-width="150px">
        <el-form-item label="物流单号" prop="courier_no">
          <el-input v-model="formOrderShipment.courier_no"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogOrderShipmentVisible = false">取消</el-button>

        <el-button type="primary" @click="saveOrderShipmentData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 订单发货 -->
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  data() {
    return {
      canteens: [],
      shops: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        order_no: "",
        phone: "",
        name: "",
        goods_name: "",
        order_type: "",
        school_id: "",
        shop_id: "",
        start_time: "",
        end_time: "",
        order_status: "",
        pay_type: "",
        send_type: "",
        building_id: "",
        sendman_id: "",
        send_mode: "",
        activities_type: "",
        activity_type: "",
      },
      exportExcels: [],
      // 楼栋
      buildings: [],
      // 配送员
      sendmans: [],
      // 订单发货
      dialogOrderShipmentVisible: false,
      btnLoading: false,
      formOrderShipment: {
        order_no: '',
        courier_no: '',
      },
      rulesOrderShipment: {
        courier_no: [{ required: true, message: '请输入物流单号', trigger: 'change' }],
      },
    };
  },
  created() {
    this.getList();
    this.getCanteenList();
    this.getShopList();
    this.getBuildingList();
    this.getSendmanList();
  },
  computed: {
    ...mapGetters(["schools", "school_id","user"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
      this.getBuildingList();
      this.getSendmanList();
    },
    "listQuery.canteen_id": {
      handler(newValue, oldValue) {
        this.getShopList();
      },
      deep: true
    },
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      // 店铺管理员只能看到自己的订单
      if (this.user.user.type === 'shop') {
        this.listQuery.shop_id = this.user.user.shop_id;
      }
      this.listLoading = true;
      request({
        url: "/api/backend/order/list",
        method: "get",
        params: this.listQuery,
        timeout: 30000,
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCanteenList() {
      request({
        url: "/api/backend/canteen/list",
        method: "get",
        params: {
          page: 0,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.canteens = response.data.data;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          canteen_id: this.listQuery.canteen_id,
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          request({
            url: "/api/backend/user/forbid",
            method: "get",
            params: { id: val }
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    handleDetail(item) {},
    handlePrint(item) {
      this.$confirm("确定要打印订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/order/print",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleConfirm(item) {
      this.$confirm("确定要接单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/order/confirm",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSend(item) {},
    handleCancel(item) {
      this.$confirm("确定要取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/order/cancel",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleExportExcel() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery))
      listQuery.page = 1
      listQuery.limit = this.total
      request({
        url: "/api/backend/order/list",
        method: "post",
        data: listQuery,
        timeout: 12000000,
      }).then(response => {
        this.exportExcels = response.data.data
        this.$nextTick(function() {
          let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"), {raw:true});
          /* get binary string as output */
          let wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
          });
          try {
            FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "订单.xlsx");
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
          }
        })
      })
    },
    // 楼栋
    getBuildingList() {
      request({
        url: "/api/backend/building/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.buildings = response.data.data;
      });
    },
    // 配送员
    getSendmanList() {
      request({
        url: "/api/backend/sendman/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.sendmans = response.data.data;
      });
    },
    // 订单发货
    resetFormOrderShipment() {
      this.formOrderShipment = {
        order_no: '',
        courier_no: '',
      }
      this.btnLoading = false
    },
    handleOrderShipment(row){
      this.resetFormOrderShipment()
      this.formOrderShipment.order_no = row.order_no
      this.dialogOrderShipmentVisible = true
      this.$nextTick(() => {
        this.$refs['formOrderShipment'].clearValidate() //清除校验结果
      })
    },
    saveOrderShipmentData(){
      this.$refs['formOrderShipment'].validate(valid => {
        if (valid) {
          this.btnLoading = true
          request({
            url: '/api/backend/order/shipment',
            method: 'post',
            data: this.formOrderShipment
          }).then((response) => {
            this.btnLoading = false
            this.dialogOrderShipmentVisible = false
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getList()
          }).catch((err) => {
            this.btnLoading = false
          })
        }
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.custom-inline-form {
  margin-bottom: 10px;
  .el-row {
    padding: 4px 0;
    font-weight: bold;
  }
  .el-form-item {
    margin-bottom: 4px !important;
  }
  .el-form-item__label {
    line-height: 20px !important;
  }
  .el-form-item__content {
    line-height: 20px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
